// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-about-us-index-tsx": () => import("./../../../src/pages/about-us/index.tsx" /* webpackChunkName: "component---src-pages-about-us-index-tsx" */),
  "component---src-pages-blog-index-tsx": () => import("./../../../src/pages/blog/index.tsx" /* webpackChunkName: "component---src-pages-blog-index-tsx" */),
  "component---src-pages-book-a-demo-index-tsx": () => import("./../../../src/pages/book-a-demo/index.tsx" /* webpackChunkName: "component---src-pages-book-a-demo-index-tsx" */),
  "component---src-pages-customer-terms-index-tsx": () => import("./../../../src/pages/customer-terms/index.tsx" /* webpackChunkName: "component---src-pages-customer-terms-index-tsx" */),
  "component---src-pages-data-profile-index-tsx": () => import("./../../../src/pages/data-profile/index.tsx" /* webpackChunkName: "component---src-pages-data-profile-index-tsx" */),
  "component---src-pages-grovia-alternative-index-tsx": () => import("./../../../src/pages/grovia-alternative/index.tsx" /* webpackChunkName: "component---src-pages-grovia-alternative-index-tsx" */),
  "component---src-pages-hiring-index-tsx": () => import("./../../../src/pages/hiring/index.tsx" /* webpackChunkName: "component---src-pages-hiring-index-tsx" */),
  "component---src-pages-how-it-works-index-tsx": () => import("./../../../src/pages/how-it-works/index.tsx" /* webpackChunkName: "component---src-pages-how-it-works-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-pilive-2022-index-tsx": () => import("./../../../src/pages/pilive2022/index.tsx" /* webpackChunkName: "component---src-pages-pilive-2022-index-tsx" */),
  "component---src-pages-pricing-index-tsx": () => import("./../../../src/pages/pricing/index.tsx" /* webpackChunkName: "component---src-pages-pricing-index-tsx" */),
  "component---src-pages-privacy-entity-index-tsx": () => import("./../../../src/pages/privacy-entity/index.tsx" /* webpackChunkName: "component---src-pages-privacy-entity-index-tsx" */),
  "component---src-pages-privacy-index-tsx": () => import("./../../../src/pages/privacy/index.tsx" /* webpackChunkName: "component---src-pages-privacy-index-tsx" */),
  "component---src-pages-semrush-alternative-index-tsx": () => import("./../../../src/pages/semrush-alternative/index.tsx" /* webpackChunkName: "component---src-pages-semrush-alternative-index-tsx" */),
  "component---src-pages-website-terms-2021-index-tsx": () => import("./../../../src/pages/website-terms-2021/index.tsx" /* webpackChunkName: "component---src-pages-website-terms-2021-index-tsx" */),
  "component---src-pages-website-terms-index-tsx": () => import("./../../../src/pages/website-terms/index.tsx" /* webpackChunkName: "component---src-pages-website-terms-index-tsx" */),
  "component---src-templates-article-tsx": () => import("./../../../src/templates/Article.tsx" /* webpackChunkName: "component---src-templates-article-tsx" */),
  "component---src-templates-author-tsx": () => import("./../../../src/templates/Author.tsx" /* webpackChunkName: "component---src-templates-author-tsx" */)
}

