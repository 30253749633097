module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-preact/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"gatsbyRemarkPlugins":[{"resolve":"gatsby-remark-images","options":{"linkImagesToOriginal":false,"maxWidth":1200}},{"resolve":"gatsby-remark-autolink-headers","options":{"icon":false,"elements":["h2"]}},{"resolve":"gatsby-remark-relative-links","options":{"domainRegex":{}}},{"resolve":"gatsby-remark-external-links"}],"extensions":[".mdx"],"defaultLayouts":{},"lessBabel":false,"remarkPlugins":[],"rehypePlugins":[],"mediaTypes":["text/markdown","text/x-markdown"]},
    },{
      plugin: require('../node_modules/gatsby-plugin-web-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"custom":{"families":["GT Super Display"],"urls":["/fonts/faces.css"]},"google":{"families":["Work Sans:400,700,900&display=swap"]}},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Breezy","short_name":"Breezy","start_url":"/","background_color":"#ffffff","theme_color":"#0163FA","display":"minimal-ui","icon":"src/images/favicon.svg","icons":[{"src":"/images/android-chrome-192x192.png","sizes":"192x192","type":"image/png"},{"src":"/images/android-chrome-512x512.png","sizes":"512x512","type":"image/png"},{"src":"/images/favicon-16x16.png","sizes":"16x16","type":"image/png"},{"src":"/images/favicon-32x32.png","sizes":"32x32","type":"image/png"},{"src":"/images/mstile-150x150.png","sizes":"150x150","type":"image/png"}],"cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"dc2766f7d1da31ecc68da5cb54c46435"},
    },{
      plugin: require('../node_modules/gatsby-plugin-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"G-NSJ9K73LS1"},
    },{
      plugin: require('../node_modules/gatsby-plugin-iubenda-cookie-footer/gatsby-browser.js'),
      options: {"plugins":[],"iubendaOptions":{"consentOnContinuedBrowsing":false,"lang":"en-GB","siteId":2336027,"cookiePolicyId":43941214,"cookiePolicyUrl":"https://breezy.io/privacy/?ifr=true","banner":{"acceptButtonDisplay":true,"customizeButtonDisplay":true,"acceptButtonColor":"#0163FA","acceptButtonCaptionColor":"white","customizeButtonColor":"#DADADA","customizeButtonCaptionColor":"#4D4D4D","rejectButtonColor":"#000000","rejectButtonCaptionColor":"white","listPurposes":true,"position":"bottom","textColor":"black","backgroundColor":"white"}}},
    },{
      plugin: require('../node_modules/gatsby-plugin-netlify-cms/gatsby-browser.js'),
      options: {"plugins":[],"enableIdentityWidget":false,"publicPath":"admin"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-ts-config/gatsby-browser.js'),
      options: {"plugins":[{"resolve":"/opt/build/repo/node_modules/gatsby-plugin-sitemap","id":"d46b197f-6632-536b-97c1-0d4adca9784c","name":"gatsby-plugin-sitemap","version":"2.4.17","pluginOptions":{"plugins":[],"exclude":["/404.html","/404"]},"nodeAPIs":["onPostBuild"],"browserAPIs":[],"ssrAPIs":["onRenderBody"]}],"babel":false,"configDir":"gatsby","tsNode":true,"siteMetadata":{"siteUrl":"https://breezy.io"}},
    }]
