import ReactDOM from 'react-dom'
// import 'lazysizes'
import "../src/styles/main.css"

// const loadableReady = require("@loadable/component").loadableReady
// export const replaceHydrateFunction = () => {
//   return (element, container, callback) => {
//     loadableReady(() => {
//       ReactDOM.render(element, container, callback)
//     })
//   }
// }
